<!-- 产品与服务1 智慧养老系统 -->
<template>
    <div class="product1">
        <banner-bar></banner-bar>

        <div class="boxes1" id="jumpPoint">
            <div class="pub_title_area">产品名称</div>
            <div class="name">智慧养老系统</div>
            <img src="./../../assets/Product1/img1.png" alt="">
        </div>

        <div class="boxes2">
            <div class="pub_title_area">产品详情</div>
            <div class="content_area" v-for="(info,index) in infoes" :key="index">
                <div class="left">
                    <div class="title">{{info.title}}</div>
                    <div class="content">{{info.content}}</div>
                </div>
                <img class="img_url" :src="info.imgUrl" alt="">
            </div>
        </div>

        <div class="boxes3">
            <div class="title">功能总览</div>
            <div class="area1">
                <div class="per_tip" v-for="(item,index) in details" :key="index">
                    <div class="per_title">{{item.title}}</div>
                    <div class="per_branch" v-for="(branch,j) in item.branch" :key="j">
                        <div class="line"></div>
                        <div class="branch_font">{{branch}}</div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
            <div class="area2">
                <div class="font1">老友圈友广场和我的圈子2个切换标签，可以快速切换对应的内容。能让老人能有更好的社会交涉的需求，能让老人之间不再孤单。</div>
                <div class="font2">医疗服务包括了在线医疗、专科问诊、医疗功能三大功能模块。开发的这个模块的起初能让老人们有个更快更加效率知道自己的身体情况，而且能根据自己身体原因能及时找到相应专科的专家询问。</div>
                <div class="font3">我们也指定了医疗功能模块，能根据老人身体的不同设定了相应的功能，比如老人不方便的时候我们可以提供上门服务能更好解决老人的问题。</div>
                <div class="font4">我们额外增加了智能设备这个功能，就比如说智慧手环能通过这个app能看到老人心率、血压、定位等。相同智能手环也有这种功能，这样能更实施观看老人身体状况，能让家里人察觉出来。后续会有更多设备添加进去，通过这个程序能实现一体化。</div>
                <img class="img4" src="./../../assets/Product1/img4.png" alt="">
                <img class="img5" src="./../../assets/Product1/img5.png" alt="">
                <img class="img6" src="./../../assets/Product1/img6.png" alt="">
            </div>
        </div>

        <div class="boxes4">
            <div class="title">智慧养老可视化大屏</div>
            <img class="img7" src="./../../assets/Product1/img7.png" alt="">
            <img class="img8" src="./../../assets/Product1/img8.png" alt="">
        </div>
    </div>
</template>
<script>
import bannerBar from './../../components/bannerBar/bannerBar.vue'
export default{
    name:'Product1',
    components:{
        bannerBar
    },
    data(){
        return{
            infoes:[
                {
                    title:'产品背景',
                    content:'2021年5月11日上午10时，国家统计局局长宁吉喆在会上通报：我国60岁及以上人口的比重上升5.44个百分点。人口老龄化程度进一步加深，未来一段时期将持续面临人口长期均衡发展的压力。这一数据并且逐年增加。。如何让每个老人老有所依、老有所养，是国家政府同时也是每个家庭考虑的问题，现在空巢老人、留守老人居多，一些老人的健康问题、生活问题存在非常大的问题，老所依APP就是一款解决老人生活、健康问题的APP，用户可以根据老所依非常便捷预约生活、医疗、休闲服务，让每个老人、老有所依。',
                    imgUrl:require('./../../assets/Product1/img2.png')
                },
                {
                    title:'智慧养老平台',
                    content:'云谷智慧养老院服务管理平台是浙江云谷数据有限公司为养老机构、养老院、敬老院、养老福利院、老年公寓等养老服务机构量身定制开发的专业的养老APP软件产品；涵盖医疗服务、社区服务、养老机构、上门服务、助餐服务、老人活动参加、老人之间的社交老友圈等服务。 专门为此还开发出了智慧养老平台可视化大屏，方便观看老人的身体数据等待。通过明确部门职能，精简业务流程，优化服务项目，科学规范管理，提高养老机构业务处理效率和管理效率，减少资源浪费，提升核心竞争力。',
                    imgUrl:require('./../../assets/Product1/img3.png')
                },
            ],
            details:[
                {
                    title:'医疗服务',
                    branch:['在线医疗','专科问诊','医疗功能'],
                },
                {
                    title:'设备入网',
                    branch:['智能手环'],
                },
                {
                    title:'上门服务',
                    branch:['室内清洁','家具保养','家电维修'],                
                },
                {
                    title:'养老机构',
                    branch:['数 量','机构类别'],                
                },
            ],
        }
    },

    created(){
        this.$nextTick(()=>{
            this.tojumpPoint()
        })
    },

    methods:{
        tojumpPoint(){
            document.getElementById('jumpPoint').scrollIntoView({
                behavior:'smooth',
                block:'start'
            })
        }
    }
}
</script>
<style scoped lang="scss">
@import './Product1.scss'
</style>


